<template>
		<div id="app">
				<AufmassForm></AufmassForm>
		</div>
</template>

<style lang="scss">

</style>
<script>
import AufmassForm    from '@/views/AufmassForm';
export default {
		components: {AufmassForm}
}
</script>
