import Vue          from 'vue'
import VueFormulate from '@braid/vue-formulate'
import options      from './../formulate.config';

import './styles/main.scss';

import App    from './App.vue'

//import store from './store/index'
//import router from './router'

import './registerServiceWorker'


Vue.config.productionTip = false
Vue.use(VueFormulate, options)

new Vue({
    render: h => h(App)
}).$mount('#app')
