<template>
		<div id="form-view">
				<div id="myModal" class="modal">

						<!-- Modal content -->
						<div class="modal-content">
								<span class="close">&times;</span>
								<p>Some text in the Modal..</p>
						</div>

				</div>
				<div id="aufmass-form">
						<FormulateForm

								v-model="values"
						>
								<h2>Kundendaten</h2>

								<FormulateInput
										type="group"
										name="customer"
								>
										<div class="row">
												<FormulateInput
														type="select"
														name="category"
														:options="{privat: 'Privatkunde', gewerblich: 'gewerblicher Kunde'}"

														placeholder="Art"
														style="flex:0 0 12em"
												/>
												<FormulateInput type="text" name="name" placeholder="Name" validation="required"/>
										</div>

										<FormulateInput type="text" name="ansprechpartner" placeholder="Ansprechpartner" v-if="values.customer[0].category === 'gewerblich'"/>

										<div class="row">
												<FormulateInput type="text" name="ort" placeholder="Ort" style="flex:0 0 15em" max-len="30" validation="required"/>
												<FormulateInput type="text" name="strasse" placeholder="Adresse (Straße + Hausnummer)"/>
										</div>
										<div class="row">
												<FormulateInput type="text" name="adress_anmerkung" placeholder="Anmerkung (z.B. Etage/Wohneinheit/Hinterhaus o.ä.)"/>
												<FormulateInput
														type="email"
														name="email"
														placeholder="E-Mail"
												/>
										</div>
										<div class="row">
												<FormulateInput
														type="tel"
														name="telefon"
														placeholder="Telefon"
												/>
												<FormulateInput
														type="tel"
														name="mobil"
														placeholder="Mobil"
												/>
										</div>
								</FormulateInput>

								<h2>Aufmaß</h2>
								<FormulateInput
										type="tel"
										name="telefon"
										placeholder="Telefon"
								/>
								<FormulateInput
										type="group"
										name="aufmass"
										:repeatable="true"
										add-label="Aufmaß hinzufügen"
								>
										<div class="row">
												<FormulateInput
														type="text"
														name="label"
														label="Bezeichnung"
														placeholder="z.B. Wohnzimmer"
														style=""
												/>
												<FormulateInput
														type="number"
														name="area"
														label="Fläche (qm)"
														style="flex: 0 0 10em;"
												/>
										</div>

										<FormulateInput
												type="text"
												name="calc"
												placeholder="Berechnung / Teilflächen"
												style="margin-bottom: 0"
										/>
								</FormulateInput>


								<h2>Zusätzliche Infos</h2>

								<FormulateInput
										label="Rausrissarbeiten"
										type="textarea"
										name="rausriss"
								/>
								<FormulateInput
										label="Material/Materialverarbeitung"
										type="textarea"
										name="material"
								/>
								<FormulateInput
										label="Leistenmontage"
										type="textarea"
										name="leistenmontage"
								/>
								<FormulateInput
										label="Türzargen"
										type="textarea"
										name="tuerzargen"
								/>

								<FormulateInput
										label="Details"
										type="textarea"
										name="details"
								/>

								<!--						<FormulateInput
																type="image"
																name="headshot"
																label="Wählen Sie ein Bild zum Upload"
																help="Select a png, jpg or gif to upload."
																validation="mime:image/jpeg,image/png,image/gif"
														/>-->


						</FormulateForm>

						<hr>
						<FormulateForm>

								<div class="buttons">
										<FormulateInput
												type="button"
												@click="onClickSubmit"
												label="PDF erstellen"
										/>


										<FormulateInput
												type="button"
												@click="onClickReset"
												label="Daten löschen"
												data-danger="true"
										/>
								</div>
						</FormulateForm>
				</div>
				<div id="form-helper">
						<div style="position: fixed; top:15px; width: 250px">

								<div v-for="posi in values.aufmass" class="aufmass-el" @click="clickHelperPostition(posi)">
										<div>
												<span v-if="posi.label" style="text-decoration: underline; font-weight: bold">{{ posi.label }}</span>
												<span v-else style="font-style: italic;">kein Titel</span>
										</div>

										<div v-if="posi.calc">{{ posi.calc }}</div>
										<div><span>{{ posi.area }} m²</span></div>
								</div>


								<div v-if="values.aufmass.length" style="border-top:1px solid;font-weight: bold">
										<div>Summe: {{ aufmass_summe() }}m²</div>
								</div>
						</div>


				</div>

		</div>
</template>

<script>
import moment from 'moment';

const defaultData = () => ({
		additionalFields: {},
		values: {
				customer: [
						{
								category: 'privat',
								name: '',
								email: '',
								ort: '',
								strasse: '',
								adress_anmerkung: ''
						}
				],
				aufmass: []
		},
})

export default {
		name: "AufmassForm",

		data(){
				return defaultData();

		},

		methods: {
				aufmass_summe(){
						return this.values.aufmass.reduce((prev, cur) => {
								let curVal = parseFloat(cur.area) || 0;
								return prev + curVal
						}, 0)
				},

				clickHelperPostition(ev){
						console.log(ev);
				},

				onClickReset(){
						//this.$store.commit('setDefaultFormData');
						//this.values = this.$store.state.formData;
						this.values = defaultData();
				},
				onClickSubmit(){
						//this.onClickBtn();
						const generatePDF = require('../pdfgen').default;

						const output = generatePDF(this.values);

						//this.$store.commit('setPdfDoc', {pdfDoc: output.doc});
						//this.$store.commit('setDataUri', {dataUri: output.doc.output('datauristring')});
						//this.$store.commit('setFormData', {formData: this.values});

						//output.doc.output("dataurinewwindow"); // this opens a new popup,  after this the PDF opens the print window view but there are browser inconsistencies with how this is handled

						output.doc.save(output.filename, {returnPromise: true}).then(() => {
								console.log(output.doc, arguments)
						});

						//this.$router.push({
						//		name: 'Summary',
						//)

						//generatePDF(this.values);
				},


		}
}
</script>

<style scoped lang="scss">

#form-helper {
		display: none;
}

@media (min-width: 820px) {
		#form-view {
				display: flex;
		}

		#aufmass-form {
				flex: 1 1 800px;
		}
		#form-helper {
				display: block;
				width: 200px;
				padding: 0.5em;
		}

		.aufmass-el {
				> div {
						display: flex;
						overflow-wrap: anywhere;


						span:first-child {
								flex: 1 1;
						}

						span:last-child {
								flex: 1 1 130px;

						}
				}

				border-bottom: 1px solid;
		}
}

.formulate-input .formulate-input-element {
		max-width: 80em;
		margin-bottom: .1em;
}

#aufmass-form {
		padding: 2em;
		border: 1px solid #a8a8a8;
		border-radius: .5em;
		box-sizing: border-box;
}


.row {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		align-content: stretch;
		justify-content: space-between;
		margin: 0 -0.5em;

		> .formulate-input {
				flex-grow: 1;
				margin-left: 0.5em;
				margin-right: 0.5em;
		}
}


.form-title {
		margin-top: 0;
}

.aufmass-form::v-deep .formulate-input .formulate-input-element {
		max-width: none;
}


.modal {
		display: none; /* Hidden by default */
		position: fixed; /* Stay in place */
		z-index: 1; /* Sit on top */
		left: 0;
		top: 0;
		width: 100%; /* Full width */
		height: 100%; /* Full height */
		overflow: auto; /* Enable scroll if needed */
		background-color: rgb(0, 0, 0); /* Fallback color */
		background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
		background-color: #fefefe;
		margin: 15% auto; /* 15% from the top and centered */
		padding: 20px;
		border: 1px solid #888;
		width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
		color: #aaa;
		float: right;
		font-size: 28px;
		font-weight: bold;
}

.close:hover,
.close:focus {
		color: black;
		text-decoration: none;
		cursor: pointer;
}

</style>

